import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
:root {
  --black: #0b0c0c;
  --white: #fff;
  --blue: #0032a0;
  --dark-blue: #002371;
  --light-grey: #dadada;
  --navy: #001544;
  --grey: #1f2025;
  --grey-background: #efefef;
  --lfb-red: #e74d3c;
  --met-blue: #3498db;
  --aux-yellow: #f1c40f;
  --las-green: #11806a;
  --civ-orange: #E67E22;
}

  body {
    margin: 0;
    background-color: var(--grey-background);
  }

  .main {
    max-width: 960px;
    margin: 0 auto;
  }

.gatsby-image-wrapper img[src*=base64\\,] {
  image-rendering: -moz-crisp-edges;
  image-rendering: pixelated;
}

img {
  max-width: 100%;
}

.video {
  text-align: center;
  margin-bottom: 40px;
}

.video iframe {
  width: 100%;
  height: 480px;
}

.btn-primary {
  text-decoration: none;
  background-color: var(--blue);
  color: var(--white);
  padding: 10px;
  border: solid 2px var(--blue);
  border-radius: 7px;
  font-weight: 500;
  transition: 0.2s;

  &:hover {
    background-color: var(--white);
  }
}

.btn-sale {
  text-decoration: none;
  background-color: var(--lfb-red);
  color: var(--white);
  padding: 10px;
  border: solid 2px var(--lfb-red);
  border-radius: 7px;
  font-weight: 500;
  transition: 0.2s;

  &:hover {
    background-color: var(--white);
    color: var(--lfb-red);
  }
}`;
export default GlobalStyles;
