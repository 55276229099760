import React from 'react';
import Layout from './src/components/Layout';
import LayoutFiveM from './src/components/LayoutFiveM';
import LayoutFullPage from './src/components/LayoutFullPage';

export function wrapPageElement({ element, props }) {
  if (props.location.pathname.includes('/fivem/')) {
    return <LayoutFiveM {...props}>{element}</LayoutFiveM>;
  }
  if (
    props.location.pathname.includes('org-chart') ||
    props.location.pathname.includes('rank-chart')
  ) {
    return <LayoutFullPage {...props}>{element}</LayoutFullPage>;
  }

  return <Layout {...props}>{element}</Layout>;
}
