import React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import { device } from '../utils/Brekapoints';
import Footer from './Footer';
import Nav from './Nav';
import NavConnect from './NavConnect';
import ScrollToTop from './ScrollToTop';

const MainStyles = styled.main`
  display: block;
  max-width: 960px;
  margin: 0 auto;

  @media ${device.tablet} {
    padding: 0 14px;
  }

  @media ${device.mobile} {
    padding: 0 14px;
  }
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Nav />
      <NavConnect />
      <MainStyles>{children}</MainStyles>
      <Footer />
      <ScrollToTop />
    </>
  );
}
