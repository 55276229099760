import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { device } from '../utils/Brekapoints';

const FooterStyles = styled.footer`
  background-color: #1f2025;
  padding: 40px 0;
  color: var(--white);

  a {
    color: var(--white);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: var(--white);
    }
  }
`;

const FooterWrapper = styled.div`
  width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }
`;

const FooterLegal = styled.div`
  flex-grow: 1;
  flex: 1 0 33.33%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const FooterLinks = styled.ul`
  flex-grow: 2;
  flex: 2 0 66.66%;  gap: 20px; /* Adjust the gap to your liking */
  list-style: none;  
  display: grid;
  grid-template-columns: 1fr 1fr; /* This creates two columns of equal width */
  gap: 20px; /* Adjust the gap to your liking */

  @media ${device.mobile} {
    width: 100%;
  }

  .footer-link-label-wrapper {

    @media ${device.mobile} {
      a {
        &:after {
          content: '•';
          color: #DB0000;
          display: inline-block;
          text-shadow: 0 0 5px #ff0000;
        }
      }
    }
  }

  .footer-link-label {
    font-size: 14px;
    line-height: 20px;
    border-radius: 5px;
    padding: 0 8px;
    margin-left: 20px;
    text-transform: uppercase;
    background-color: #DB0000;

    @media ${device.mobile} {
      display: none;
    }
  }

  @media ${device.mobile} {
    justify-content: initial;
    flex-wrap: wrap;
    padding-left: 0;
    text-align: center;

    li {
      margin-bottom: 25px;
    }
  }
`;

const FooterLogos = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin-top: 0;

  a {
    padding: 0 20px;
  }
`;

const FooterCopyrightDisclaimer = styled.div`
  margin-bottom: 10px;
`;

const FooterLegalDisclaimer = styled.div`
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
`;

function renderFooterLinks(name, link, displayLabel, labelText) {
  if (displayLabel && labelText) {
    if (link.charAt(0) === '/') {
      return (
        <span className="footer-link-label-wrapper">
          <Link to={link}>{name}</Link>
          <span className="footer-link-label">{labelText}</span>
        </span>
      );
    }
    <a href={link} target="_blank" rel="noreferrer">
      {name}
    </a>;
  } else {
    if (link.charAt(0) === '/') {
      return <Link to={link}>{name}</Link>;
    }

    return (
      <a href={link} target="_blank" rel="noreferrer">
        {name}
      </a>
    );
  }
}

export default function Footer() {
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: allSanitySiteSettings {
        nodes {
          copyrightText
          legalFooterDisclaimer
          footerLinks {
            _key
            name
            link
            displayLabel
            labelText
          }
          footerLogos {
            _key
            link
            name
            logo {
              asset {
                gatsbyImageData(width: 32, height: 32)
              }
            }
          }
        }
      }
    }
  `);

  const siteSettingsData = siteSettings.nodes[0];

  return (
    <FooterStyles>
      <FooterWrapper>
        <FooterLinks>
          {siteSettingsData.footerLinks.map((footerLink) => (
            <li key={footerLink._key}>
              {renderFooterLinks(
                footerLink.name,
                footerLink.link,
                footerLink.displayLabel,
                footerLink.labelText
              )}
            </li>
          ))}
        </FooterLinks>
        <FooterLegal>
          <FooterLogos>
            {siteSettingsData.footerLogos.map((footerLogo) => (
              <li key={footerLogo._key}>
                <a
                  href={footerLogo.link}
                  alt={footerLogo.name}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GatsbyImage
                    image={footerLogo.logo.asset.gatsbyImageData}
                    alt={footerLogo.name}
                    className="block-image"
                  />
                </a>
              </li>
            ))}
          </FooterLogos>
          <FooterCopyrightDisclaimer>
            {siteSettingsData.copyrightText}
          </FooterCopyrightDisclaimer>
          <FooterLegalDisclaimer>
            {siteSettingsData.legalFooterDisclaimer}
          </FooterLegalDisclaimer>
        </FooterLegal>
      </FooterWrapper>
    </FooterStyles>
  );
}
