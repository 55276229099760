import { createGlobalStyle } from 'styled-components';
import { device } from '../utils/Brekapoints';

const Typography = createGlobalStyle`
  html {
    font-family: futura-pt, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: var(--black);
    font-size: 62.5%;
  }
  body {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
    margin: 0;
  }
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 40px;
    font-display: optional;

    @media ${device.mobile} {
      font-size: 3.2rem;
      line-height: 3.2rem;
      margin-bottom: 20px;
    }
  }
  h2 {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 30px;

    @media ${device.mobile} {
      font-size: 2.4rem;
      line-height: 2.4rem;
      margin-bottom: 15px;
    }
  }
  h3 {
    font-size: 2.2rem;
    line-height: 2.2rem;
    margin-bottom: 20px;

    @media ${device.mobile} {
      font-size: 1.8rem;
      line-height: 1.8rem;
      margin-bottom: 15px;
    }
}
  h4 {
    font-size: 1.1875rem;
    margin-bottom: 20px;
}

.content {
  a {
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}
  a {
    color: var(--blue);
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;

    }

    &:hover {
    color: var(--blue);

    }

  }
    a.link-white {
        color: var(--white);
        text-decoration: none;

        &:hover {
            color: var(--white);
            text-decoration: underline;
        }

    }

  .center {
    text-align: center;
  }
`;

export default Typography;
