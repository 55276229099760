import React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import { device } from '../utils/Brekapoints';

const MainStyles = styled.main`
  display: block;
  max-width: 960px;
  margin: 0 auto;

  @media ${device.mobile} {
    padding: 0 14px;
  }
`;

export default function LayoutFiveM({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <MainStyles>{children}</MainStyles>
    </>
  );
}
