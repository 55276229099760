/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ScrollToTopWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
`;

const ScrollToTopButton = styled.div`
  background-color: var(--blue);
  transform: rotate(90deg);
  font-size: 5rem;
  font-weight: 500;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  padding: 8px 15px 20px 15px;
`;

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility, { passive: true });

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);
  return (
    <ScrollToTopWrapper>
      {isVisible && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <ScrollToTopButton onClick={scrollToTop}>«</ScrollToTopButton>
      )}
    </ScrollToTopWrapper>
  );
}
