const size = {
  mobile: '425px',
  tablet: '768px',
  desktop: '960px',
};

export const device = {
  mobile: `(max-width: ${size.tablet})`,
  tablet: `(max-width: ${size.desktop})`,
};
