import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from '../utils/Brekapoints';

const NavConnectStyles = styled.div`
  background: var(--navy);
  color: var(--white);

  @media ${device.mobile} {
    padding: 0 14px;
  }
`;

const NavConnectWrapper = styled.div`
  font-display: optional;
  max-width: 960px;
  margin: 0 auto;
  padding: 14px 0;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.mobile} {
    font-size: 1.6rem;
  }
`;
const ServerConnect = styled.div`
  a {
    color: var(--white);

    &:hover {
      text-decoration: underline;
    }
  }
`;

const NavOnlinePlayerNames = styled.div`
  max-width: 960px;
  margin: 0 auto;
  text-align: center;

  &.player-names-visible {
    visibility: visible;
    opacity: 1;
    padding-bottom: 20px;
    transition: all 1s;
  }

  &.player-names-hidden {
    visibility: hidden;
    opacity: 0;
    height: 0;
  }
`;

const PlayersOnline = styled.div`
  cursor: pointer;

  &.player-hidden:after {
    content: '▼';
    font-size: 1.6rem;
    margin-left: 10px;
  }

  &.player-visible:after {
    content: '▲';
    font-size: 1.6rem;
    margin-left: 10px;
  }
`;

function FetchOnlinePlayers(returnNames) {
  const [playerCount, setPlayerCount] = useState();
  const [playerNames, setPlayerNames] = useState();

  useEffect(() => {
    fetch(process.env.GATSBY_TX_PLAYERS_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPlayerCount(res.length);
        setPlayerNames(res.map((player) => player.name).join(', '));
      })
      .catch((error) => {
        setPlayerCount('E');
      });
  }, []);
  return returnNames ? playerNames : playerCount;
}

export default function NavConnect() {
  const { siteSettings } = useStaticQuery(graphql`
    query {
      siteSettings: allSanitySiteSettings {
        nodes {
          playPMPText
          playPMPSlug {
            current
          }
        }
      }
    }
  `);
  const siteSettingsData = siteSettings.nodes[0];
  const [showPlayerNames, setShowPlayerNames] = useState(false);
  const handleShowPlayersClick = () => {
    setShowPlayerNames(!showPlayerNames);
  };

  return (
    <NavConnectStyles>
      <NavConnectWrapper>
        <ServerConnect>
          <a
            href={siteSettingsData.playPMPSlug.current}
            target="_blank"
            rel="noreferrer"
          >
            {siteSettingsData.playPMPText}
          </a>
        </ServerConnect>
        <PlayersOnline
          className={showPlayerNames ? 'player-visible' : 'player-hidden'}
          onClick={handleShowPlayersClick}
        >
          Players Online: {FetchOnlinePlayers(false)}
        </PlayersOnline>
      </NavConnectWrapper>
      <NavOnlinePlayerNames
        className={
          showPlayerNames ? 'player-names-visible' : 'player-names-hidden'
        }
      >
        {FetchOnlinePlayers(true)}
      </NavOnlinePlayerNames>
    </NavConnectStyles>
  );
}
